import { FeatureCreditManagement } from '@wallet-manager/pfh-node-def-types/dist/src/FeatureCode';

import api from '../../../api';
import { Ipage } from '../../../api/types';
import { Iprefix } from '../../../hooks/useTranslation';
import { initZusParams } from '../../../utils/config';
import { createZusInstance } from '../../../zustand/store';

const translatePrefix: Iprefix = "cardTransaction";
const PermissionKey = FeatureCreditManagement.CardTransaction;

interface Ifield {
  merchantId: string;
  programName: string[];
  customerNumber: string;
  cardNumber: string;
  transactionId: string;
  transactionType: string;
  subType: string;
  transactionSource: string;
  retrievalReferenceNumber: string;
  isAuthorization: string;
  isReversal: string;
  completedTransaction: string;
  programAgentId: string;
  distributorAgentId: string;
  hidden: string;
  settlementDate: string;
}

const initFields: Ifield = {
  merchantId: "",
  programName: [],
  customerNumber: "",
  cardNumber: "",
  transactionId: "",
  transactionType: "",
  subType: "",
  transactionSource: "",
  retrievalReferenceNumber: "",
  isAuthorization: "",
  isReversal: "",
  completedTransaction: "",
  programAgentId: "",
  distributorAgentId: "",
  hidden: "",
  settlementDate: "",
};

interface IsearchParam {
  merchantId: string;
  programNames: string[];
  customerNumber: string;
  cardNumber: string;
  transactionId: string;
  transactionType: string;
  transactionSubType: string;
  transactionSource: string;
  retrievalReferenceNumber: string;
  creationTimeFrom: string;
  creationTimeTo: string;
  isAuthorization: string;
  reversal: string;
  txCompleted: string;
  programAgentId: string;
  distributorAgentId: string;
  hidden: string;
  settlementDate: string;
}

const useZusParams = createZusInstance<Ipage & Partial<IsearchParam>>(
  initZusParams
);

const apiObj: {
  export: (params: any, config?: any) => any;
  table: (params: any, config?: any) => any;
} = Object.freeze({
  export: api.CreditManagement.cardTransaction.getExport,
  table: api.CreditManagement.cardTransaction.getAll,
});

const translateKeyObj = Object.freeze({
  creationTime: "creation_time",
  merchantName: "merchant_name",
  programName: "program_name",
  customerNumber: "customer_number",
  cardNumber: "card_number",
  panLast4: "pan_last_4",
  transactionId: "transaction_id",
  transactionType: "transaction_type",
  transactionSource: "transaction_source",
  retrievalReferenceNumber: "retrieval_reference_number",

  billingCurrency: "billing_currency",
  billingAmount: "billing_amount",
  isAuthorization: "is_authorization",
  authorizationCode: "authorization_code",
  completedTransaction: "completed_transaction",
  acquirerDetails: "acquirer_details",
  acquiringInstitutionId: "acquiring_institution_id",
  acquirerCurrency: "acquirer_currency",
  acquirerAmount: "acquirer_amount",
  transactionDetails: "transaction_details",
  isAtm: "is_atm",
  isInCountry: "is_in_country",
  onlineTransaction: "online_transaction",
  posTransaction: "pos_transaction",
  isReversal: "is_reversal",
  cardAcceptorDetails: "card_acceptor_details",
  merchantCategoryCodes: "merchant_category_codes",
  cardAcceptorId: "card_acceptor_id",
  cardAcceptorName: "card_acceptor_name",
  cardAcceptorCity: "card_acceptor_city",
  cardAcceptorCountryCode: "card_acceptor_country_code",
  memo: "memo",
  fee: "fee",
  feeCurrency: "fee_currency",
  visaRebate: "visa_rebate",
  visaFee: "visa_fee",
  swapCost: "swap_cost",
  systemCost: "system_cost",
  agentRebate: "agent_rebate",
  subType: "sub_type",
  externalReference: "external_reference",
  programAgentId: "program_agent_id",
  distributorAgentId: "distributor_agent_id",
  hidden: "hidden",
  settlementDate: "settlement_date",
  phTransactionSubType: "ph_transaction_sub_type",
});

const omitKeyObj = Object.freeze({
  export: ["rawData"],
  table: [],
});

type TableApiRes = {
  id: number;
  taskId: number;
  merchantId: number;
  retrievalReferenceNumber: number;
  transactionId: number;
  accountId: number;
  originalTransactionId: number;
  relatedTransactionId: number;
  programName: string;
  notificationId: string;
  txCompleted: boolean;
  isAtm: boolean;
  isAuthorization: boolean;
  isInCountry: boolean;
  isOnline: boolean;
  isPos: boolean;
  creationTime: string;
  settlementDate: null;
  currency: string;
  amount: string;
  transactionSource: number;
  transactionType: number;
  reversal: boolean;
  feeAdjustment: string;
  hidden: boolean;
  feeAmount: string;
  feeCurrency: null;
  taxAmount: string;
  taxCurrency: null;
  externalReference: string;
  exchangedAmount: null;
  exchangedCurrency: null;
  exchangeRate: null;
  memo: string;
  customerNumber: string;
  parentCustomerNumber: null;
  walletId: null;
  dpan: null;
  trid: null;
  callerReference: null;
  complianceCurrencyAmount: null;
  portfolioType: null;
  cardNumber: null;
  panLast4: string | null;
  cardNumberHash: null;
  mti: null;
  acquirerCurrency: null;
  acquirerAmount: null;
  settlementCurrency: null;
  settlementAmount: null;
  cardAcceptorName: null;
  cardAcceptorCity: null;
  cardAcceptorCountryCode: null;
  mcc: null;
  cardAcceptorId: null;
  processingCode: null;
  settlementCycle: null;
  authCode: null;
  createdAt: string;
  updatedAt: string;
  reversalTime: null;
  visaRebate: null;
  visaFee: null;
  swapCost: null;
  systemCost: any;
  agentRebate: any;
  transactionSubType: number;
  acquiringInstitutionId: null;
  programAgentId: string;
  distributorAgentId: string;
};

export {
  translatePrefix,
  PermissionKey,
  type Ifield,
  type IsearchParam,
  type TableApiRes,
  initFields,
  initZusParams,
  useZusParams,
  apiObj,
  translateKeyObj,
  omitKeyObj,
};
