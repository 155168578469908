import { MpTextField } from "../../../../../components"
import { useTranslation } from "../../../../../hooks";

interface IProps {
  label?: string;
  value: any;
  onChange: (e: React.ChangeEvent<any>) => void;
}


const CustomerLabelSingleSelection = (props: IProps) => {
  const { label, value, onChange, } = props;

  const { tc } = useTranslation("common");

  return (
    <MpTextField
      label={label || tc("phInputField", { fieldName: tc('customerLabel') })}
      value={value}
      onChange={onChange}
    />
  )
}

export default CustomerLabelSingleSelection;