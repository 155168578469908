import { useEffect, useState } from "react";
import { useTranslation } from "../../../../../hooks";
import { useZusDialogStore } from "../../../../../zustand/store";
import { apiObj as api, translateKeyObj as TK } from "../../config";
import {
  Column,
  LargePrice,
  Row,
  SmallTextRow,
  WhiteTag,
} from "./components/styling";
import {
  displayAmountCurrying,
  toDisplayTime,
} from "../../../../../utils/helper";
import Category from "./components/Category";
import { ButtonMenu, LoadingDialog } from "../../../../../components";
import DialogBillingHistory from "./components/DialogBillingHistory";
import { IBillingHistory } from "./types/IBillingHistory";
import { addDays } from "date-fns";

const FinancialTabContent = () => {
  const { t } = useTranslation("customerAccount");

  const zusDialog = useZusDialogStore();
  const { customerNumber, merchantId } = zusDialog.meta;

  const [balanceData, setBalanceData] = useState<any>();

  const [isBillingHistoryDialogOpen, setIsBillingHistoryDialogOpen] =
    useState(false);

  const [lastCycle, setLastCycle] = useState<IBillingHistory>();

  async function fetchBalanceData() {
    const res = await api.getOffering({ customerNumber, merchantId });
    if (res) {
      setBalanceData(res);
    }
  }

  async function getBillingHistory() {
    const res: IBillingHistory[] = await api.getBillingHistory({
      merchantId,
      customerNumber,
      limit: 1,
    });

    if (!res) return;

    setLastCycle(res[0]);
  }

  useEffect(() => {
    fetchBalanceData();
    getBillingHistory();
  }, []);

  const toAmount = (amount: number | string | undefined) => {
    const displayAmount = displayAmountCurrying(0, 2);
    return `${t("hkd")} ${displayAmount(amount || 0)}`;
  };

  const toDisplayDate = (dateString?: string | Date) => {
    if (!dateString) {
      return "-";
    }
    return toDisplayTime(dateString ?? "", "yyyy-MM-dd");
  };

  const getPenalizationDate = (dateString?: string) => {
    if (!dateString) {
      return "-";
    }
    const dateObj = new Date(dateString);
    const addedDate = addDays(dateObj, 1);
    return toDisplayDate(addedDate);
  };

  if (!balanceData) return <LoadingDialog forceOpen={true} />;

  return (
    <>
      <DialogBillingHistory
        isOpen={isBillingHistoryDialogOpen}
        totalBalance={balanceData?.totalBalance}
        handleCloseDialog={() => setIsBillingHistoryDialogOpen(false)}
      />
      <Category title={t("balance")}>
        <Row>
          <Column>
            <WhiteTag>{t("total_balance")}</WhiteTag>
            <LargePrice>{toAmount(balanceData?.totalBalance)}</LargePrice>
            <SmallTextRow>
              {`${t("available_balance")}: ${toAmount(
                balanceData?.availableCredit
              )}`}
            </SmallTextRow>
            <SmallTextRow>
              {`${t("excess_credit_of")}: ${toAmount(
                balanceData?.excessBalance
              )}`}
            </SmallTextRow>
          </Column>
          <Column>
            <WhiteTag>{t("credit_limit")}</WhiteTag>
            <LargePrice>{toAmount(balanceData?.overallCreditLimit)}</LargePrice>
            <SmallTextRow>
              {`${t("over_limit_allowance")}: ${
                balanceData?.overCreditLimitEnabled
                  ? t("enabled")
                  : t("disabled")
              }`}
            </SmallTextRow>
          </Column>
        </Row>
      </Category>
      <Category
        title={t("last_cycle")}
        buttonMenu={
          <ButtonMenu
            iconSx={{ rotate: "90deg" }}
            options={[
              {
                name: t("billing_history"),
                onClickFn: () => setIsBillingHistoryDialogOpen(true),
              },
            ]}
          />
        }
      >
        <Row>
          <Column>
            <WhiteTag>{t("statement_balance")}</WhiteTag>
            <LargePrice>{toAmount(balanceData?.statementBalance)}</LargePrice>
            <SmallTextRow>
              {`${t("total_due")}: ${toAmount(lastCycle?.totalDue)}`}
            </SmallTextRow>
          </Column>
          <Column>
            <WhiteTag>{t("due_date")}</WhiteTag>
            <LargePrice>
              {toDisplayDate(lastCycle?.repaymentPeriodEnd)}
            </LargePrice>
            <SmallTextRow>
              {`${t("penalization_date")}: ${getPenalizationDate(
                lastCycle?.gracePeriodEnd
              )}`}
            </SmallTextRow>
          </Column>
        </Row>
      </Category>
    </>
  );
};

export default FinancialTabContent;
