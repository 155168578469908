import { isNil } from "lodash";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useQuery } from "react-query";

import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
} from "@mui/x-data-grid";
import { EnumE6TransactionType } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Statement";
import { EnumTransactionSource } from "@wallet-manager/pfh-node-def-types/dist/src/E6Enum";

import { OpRedirect } from "../../../assets/icons";
import { LoadingDialog } from "../../../components";
import { ExportBtn, OpIconButton } from "../../../components/Button";
import {
  CustomPagination,
  NoRowsOverlay,
  TableTab,
} from "../../../components/Layout";
import { Container } from "../../../components/MuiGenerals";
import {
  useAlerting,
  useGetMerchant,
  useListMappingTransform,
  usePermission,
  useTranslation,
} from "../../../hooks";
import useCurrencyList from "../../../hooks/useCurrencyList";
import { useGenGridCol } from "../../../utils/ComponentHelper";
import { dataGridDefaults } from "../../../utils/constant";
import {
  displayAmountCurrying,
  downloadFiles,
  getFullApiResponse,
  toDisplayTime,
} from "../../../utils/helper";
import { customSx } from "../../../utils/styling";
import { useZusDialogStore } from "../../../zustand/store";
import {
  apiObj as api,
  omitKeyObj,
  PermissionKey,
  TableApiRes,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from "./config";
import DetailsDialog from "./DetailsDialog";
import { getTransactionSubTypeTranslateKey } from "../../../features/common/helpers/getTransactionSubTypeDisplayValue";

function ViewTable() {
  const { alerting } = useAlerting();
  const { tc } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const [count, setCount] = useState(0);
  const listMapping = useListMapping("export");

  const { hasPermission } = usePermission();

  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      api.export({ ...zusParams.body, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = omitKeyObj.export;

    const res = listMapping(rawRes, omitKeys);
    const config = {};
    downloadFiles(`Card Transaction`, res, config);
  };
  return (
    <Container
      style={customSx.datagridContainer}
      maxWidth={false}
      disableGutters
    >
      <ExportBtn
        isShown={hasPermission(PermissionKey.Export)}
        onExport={onExport}
      />
      <TableTab>
        <DetailsDialog />
        <TableList setCount={setCount} />
      </TableTab>
    </Container>
  );
}

function TableList(props: { setCount: Dispatch<SetStateAction<number>> }) {
  const { setCount } = props;

  const zusParams = useZusParams();

  const zusDialog = useZusDialogStore();

  const listMapping = useListMapping("table");

  const { t } = useTranslation(translatePrefix);

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => api.table(zusParams.body),
  });

  const { rows = [], count = 0 } =
    (listRes.data as any as { rows: any[]; count: number }) || {};

  const omitKeys = omitKeyObj.table;
  const content: GridRowsProp = listMapping(rows, omitKeys);

  const renderDetailCell = (params: GridRenderCellParams<any, any, any>) => {
    const field = params.field;

    const { rawData = {} } = params.row || {};

    const handleOpenDialog = () =>
      zusDialog.open("detailsDialog", { field, rawData });

    return (
      <OpIconButton
        title={t(field)}
        svgUrl={OpRedirect}
        onClick={handleOpenDialog}
        size="1.5rem"
      />
    );
  };

  useEffect(() => setCount(count), [listRes]);

  const columns: GridColDef[] = [
    useGenGridCol(TK.merchantName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.transactionId, {
      minWidth: 150,
    }),
    useGenGridCol(TK.programName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.programAgentId, { minWidth: 150 }),
    useGenGridCol(TK.distributorAgentId, { minWidth: 150 }),
    useGenGridCol(TK.customerNumber),
    useGenGridCol(TK.cardNumber, {
      minWidth: 150,
    }),
    useGenGridCol(TK.panLast4, {
      minWidth: 150,
    }),
    useGenGridCol(TK.transactionType),
    useGenGridCol(TK.subType, {
      minWidth: 150,
    }),
    useGenGridCol(TK.transactionSource),
    useGenGridCol(TK.acquirerCurrency),
    useGenGridCol(TK.acquirerAmount),
    useGenGridCol(TK.billingCurrency, { minWidth: 150 }),
    useGenGridCol(TK.billingAmount, { minWidth: 150 }),
    useGenGridCol(TK.feeCurrency, { minWidth: 150 }),
    useGenGridCol(TK.fee, { minWidth: 150 }),
    useGenGridCol(TK.visaRebate, { minWidth: 150 }),
    useGenGridCol(TK.visaFee, { minWidth: 150 }),
    useGenGridCol(TK.swapCost, { minWidth: 150 }),
    useGenGridCol(TK.systemCost, { minWidth: 150 }),
    useGenGridCol(TK.agentRebate, { minWidth: 150 }),
    useGenGridCol(TK.isAuthorization),
    useGenGridCol(TK.authorizationCode, {
      minWidth: 150,
    }),
    useGenGridCol(TK.completedTransaction, { minWidth: 150 }),
    useGenGridCol(TK.hidden),
    useGenGridCol(TK.retrievalReferenceNumber, { minWidth: 150 }),
    useGenGridCol(TK.transactionDetails, {
      minWidth: 150,
      renderCell: renderDetailCell,
    }),
    useGenGridCol(TK.cardAcceptorDetails, {
      minWidth: 150,
      renderCell: renderDetailCell,
    }),
    useGenGridCol(TK.creationTime, {
      minWidth: 150,
    }),
    useGenGridCol(TK.settlementDate, {
      minWidth: 150,
    }),
    useGenGridCol(TK.memo, {
      minWidth: 150,
    }),
    useGenGridCol(TK.externalReference, {
      minWidth: 150,
    }),
  ];
  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;
  return (
    <>
      <DataGrid
        {...dataGridDefaults}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
    </>
  );
}

const useListMapping = (mode: "export" | "table") => {
  const { t, tb } = useTranslation("enumConstants");
  const listMappingTransform = useListMappingTransform(mode);
  const merchantObj = useGetMerchant();
  const { currencyList } = useCurrencyList();

  const getAdjustmentAmountDisplayValue = (value: string) => {
    if (value.includes("-")) {
      return value.replace(/\s/g, "").trim();
    }

    return `+${value}`;
  };

  const listMapping = (array: any[], omitKeys: string[] = []) => {
    const res = array.map((item: TableApiRes) => {
      const toDisplayAmount = displayAmountCurrying(0, 2);

      const toDisplayFeeAmount = displayAmountCurrying(0, 2);

      const toDisplayAcquirerAmount = displayAmountCurrying(0, 2);

      const transformTo0DisplayValue = (amount: unknown) => {
        if (!Number(amount) || !amount) {
          return "0.00";
        }

        if (isNaN(Number(amount))) {
          return "";
        }

        return amount;
      };

      const transactionSubTypeTranslateKey = getTransactionSubTypeTranslateKey(
        item.transactionSubType
      );

      const mappedResult = [
        ["rawData", item],
        [TK.merchantName, merchantObj[item.merchantId]],
        [TK.transactionId, item.transactionId],
        [TK.programName, item.programName],
        [TK.programAgentId, item.programAgentId || ""],
        [TK.distributorAgentId, item.distributorAgentId || ""],
        [TK.customerNumber, item.customerNumber],
        [TK.cardNumber, item.cardNumber],
        [TK.panLast4, item.panLast4],
        [TK.transactionType, t(EnumE6TransactionType[item.transactionType])],
        [TK.subType, t(transactionSubTypeTranslateKey)],
        [
          TK.transactionSource,
          t(EnumTransactionSource[item.transactionSource]),
        ],
        [TK.acquirerCurrency, item.acquirerCurrency],
        [TK.acquirerAmount, toDisplayAcquirerAmount(item.acquirerAmount || "")],
        [TK.billingCurrency, item.currency],
        [TK.billingAmount, toDisplayAmount(item.amount)],
        [TK.feeCurrency, item.feeCurrency],
        [TK.fee, toDisplayFeeAmount(item.feeAmount)],
        [TK.visaRebate, transformTo0DisplayValue(item.visaRebate)],
        [TK.visaFee, transformTo0DisplayValue(item.visaFee)],
        [TK.swapCost, transformTo0DisplayValue(item.swapCost)],
        [TK.systemCost, transformTo0DisplayValue(item.systemCost)],
        [TK.agentRebate, transformTo0DisplayValue(item.agentRebate)],
        [TK.isAuthorization, tb(item.isAuthorization)],
        [TK.authorizationCode, item.authCode],
        [TK.completedTransaction, tb(item.txCompleted)],
        [TK.hidden, tb(item.hidden)],
        [TK.retrievalReferenceNumber, item.retrievalReferenceNumber],
        // [TK.acquiringInstitutionId, item.acquiringInstitutionId],
        [TK.isAtm, tb(item.isAtm)],
        [TK.isInCountry, tb(item.isInCountry)],
        [TK.onlineTransaction, tb(item.isOnline)],
        [TK.posTransaction, tb(item.isPos)],
        [TK.isReversal, tb(item.reversal)],
        [TK.merchantCategoryCodes, item.mcc],
        [TK.cardAcceptorId, item.cardAcceptorId],
        [TK.cardAcceptorName, item.cardAcceptorName],
        [TK.cardAcceptorCity, item.cardAcceptorCity],
        [TK.cardAcceptorCountryCode, item.cardAcceptorCountryCode],
        [TK.creationTime, toDisplayTime(item.creationTime)],
        [TK.settlementDate, toDisplayTime(item.settlementDate, 'yyyy-MM-dd')],
        [TK.memo, item.memo],
        [TK.externalReference, item.externalReference],
      ].filter(([key]) => !omitKeys.includes(key as string));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export default ViewTable;
