import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useQuery } from "react-query";

import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
} from "@mui/x-data-grid";

import { OpView } from "../../../assets/icons";
import { LoadingDialog } from "../../../components";
import { ExportBtn, OpIconButton } from "../../../components/Button";
import {
  CustomPagination,
  NoRowsOverlay,
  TableTab,
} from "../../../components/Layout";
import { Container } from "../../../components/MuiGenerals";
import {
  useAlerting,
  useGetMerchant,
  useListMappingTransform,
  usePermission,
  useTranslation,
} from "../../../hooks";
import useCurrencyList from "../../../hooks/useCurrencyList";
import { useGenGridCol } from "../../../utils/ComponentHelper";
import { dataGridDefaults } from "../../../utils/constant";
import {
  displayAmountCurrying,
  downloadFiles,
  getDecimal,
  getFullApiResponse,
  toDisplayTime,
} from "../../../utils/helper";
import { customSx } from "../../../utils/styling";
import { useZusDialogStore } from "../../../zustand/store";
import {
  apiObj as api,
  omitKeyObj,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from "./config";
import OperationDialog from "./OperationDialog";

function ViewTable() {
  const { alerting } = useAlerting();
  const { tc } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const [count, setCount] = useState(0);
  const { hasPermission } = usePermission();

  const listMapping = useListMapping("export");

  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      api.export({ ...zusParams.body, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = omitKeyObj.export;

    const res = listMapping(rawRes, omitKeys);
    const config = {};
    downloadFiles(`Customer Account`, res, config);
  };
  return (
    <Container
      style={customSx.datagridContainer}
      maxWidth={false}
      disableGutters
    >
      <ExportBtn
        isShown={hasPermission(PermissionKey.Export)}
        onExport={onExport}
      />
      <TableTab>
        <TableList setCount={setCount} />
      </TableTab>
    </Container>
  );
}

function TableList(props: { setCount: Dispatch<SetStateAction<number>> }) {
  const { setCount } = props;

  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();

  const { hasPermission } = usePermission();
  const { alerting } = useAlerting();

  const { t, tc } = useTranslation(translatePrefix);
  const listMapping = useListMapping("table");

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => api.table(zusParams.body),
  });

  const { rows = [], count = 0 } =
    (listRes.data as any as { rows: any[]; count: number }) || {};

  const omitKeys = omitKeyObj.table;

  const content: GridRowsProp = listMapping(rows, omitKeys);

  const OperationCell = (params: GridRenderCellParams<any, any, any>) => {
    const customerNumber = params.row[TK.customerNo];
    const openOperationDialog = () => {
      if (!customerNumber) {
        return alerting("error", t("no_customer_number"));
      }
      zusDialog.open("operationDialog", {
        ...params.row.rawData,
      });
    };
    return (
      <OpIconButton
        title={tc("details")}
        svgUrl={OpView}
        onClick={openOperationDialog}
      />
    );
  };

  useEffect(() => setCount(count), [listRes]);

  const columns: GridColDef[] = [
    useGenGridCol(TK.operation, { renderCell: OperationCell }),
    useGenGridCol(TK.merchantName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.partnerName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.programName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.programAgentId, {
      minWidth: 150,
    }),
    useGenGridCol(TK.distributorAgentId, {
      minWidth: 150,
    }),
    useGenGridCol(TK.customerNo, { minWidth: 150 }),
    useGenGridCol(TK.customerLabel, { minWidth: 150 }),
    useGenGridCol(
      TK.merchantCustomerReference,

      { minWidth: 180 }
    ),
    useGenGridCol(TK.currency),
    useGenGridCol(TK.maxCreditLimit),
    useGenGridCol(TK.createdBy, {
      minWidth: 150,
    }),
    useGenGridCol(TK.creationTime, { minWidth: 150 }),
    useGenGridCol(TK.lastModifiedTime, {
      minWidth: 150,
    }),
  ].filter(({ field }) =>
    field === TK.operation && !hasPermission(PermissionKey.Details.prefix)
      ? false
      : true
  );

  // const checkedPermissionColumns = useCheckColumnsPermission(
  //   columns,
  //   checkPermissionColumnList
  // );

  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;
  return (
    <>
      <OperationDialog />
      <DataGrid
        {...dataGridDefaults}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
    </>
  );
}

const useListMapping = (mode: "export" | "table") => {
  const listMappingTransform = useListMappingTransform(mode);
  const merchantObj = useGetMerchant();
  const { currencyList } = useCurrencyList();

  const listMapping = (array: any[], omitKeys: string[] = []) => {
    const res = array.map((item: any) => {
      const decimal = getDecimal(item.currency, currencyList);
      const toDisplayAmount = displayAmountCurrying(decimal);

      const mappedResult = [
        ["rawData", item],
        [TK.merchantName, merchantObj[item.merchantId]],
        [TK.partnerName, item.partnerName],
        [TK.programName, item.programName],
        [TK.programAgentId, item.programAgentId],
        [TK.distributorAgentId, item.distributorAgentId],
        [TK.customerNo, item.customerNumber],
        [TK.customerLabel, item.customerLabel],
        [TK.merchantCustomerReference, item.merchantCustomerRef],
        [TK.currency, item.currency],
        [TK.maxCreditLimit, toDisplayAmount(item.maxCreditLimit)],
        [TK.createdBy, item.createdBy],
        [TK.creationTime, toDisplayTime(item.createdDate)],
        [TK.lastModifiedTime, toDisplayTime(item.lastModifiedDate)],
      ].filter(([key]) => !omitKeys.includes(key));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export default ViewTable;
