import { useEffect, useState } from "react";
import { LoadingDialog, SingleSelection } from "../../../../../../components"
import { GridBoxSections } from "../../../../../../components/Layout";
import { Box, Button } from "../../../../../../components/MuiGenerals"
import { useTranslation } from "../../../../../../hooks";
import { apiObj as api, translateKeyObj as TK } from "../../../config";
import { EnumYerOrNo } from "../../../../../../utils/constant";
import { useZusDialogStore } from "../../../../../../zustand/store";
import { IBillingHistory } from "../types/IBillingHistory";
import { displayAmountCurrying, toDisplayTime } from "../../../../../../utils/helper";
import { addDays } from 'date-fns';

const ContentBillingHistory = ({ totalBalance }: { totalBalance?: string }) => {

  const { t } = useTranslation("customerAccount");

  const [selectedBillingCloseDate, setSelectedBillingCloseDate] = useState('');
  const [billingHistories, setBillingHistories] = useState<IBillingHistory[]>([]);
  const [selectedHistory, setSelectedHistory] = useState<IBillingHistory>();
  const [isInit, setIsInit] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const toDisplayDate = (dateString?: string | Date) =>
    toDisplayTime(dateString ?? '', 'yyyy-MM-dd')

  const selectOptions = billingHistories
    .reduce(
      (theObj: Record<string, string>, item) => ({
        ...theObj,
        [toDisplayDate(item.billingPeriodClose)]:
          item.billingPeriodClose
      }), {})

  const getPenalizationDate = (dateString?: string) => {
    if (!dateString) return ''
    const dateObj = new Date(dateString)
    const addedDate = addDays(dateObj, 1)
    return toDisplayDate(addedDate)
  }

  const zusDialog = useZusDialogStore();
  const {
    customerNumber,
    merchantId,
  } = zusDialog.meta;

  async function getBillingHistory() {
    setIsLoading(() => true)
    const res: IBillingHistory[] = await api.getBillingHistory({ merchantId, customerNumber, limit: 24 })
    if (res.length >= 1) {
      setSelectedBillingCloseDate(res[0].billingPeriodClose)
      setSelectedHistory(res[0])
    }
    setBillingHistories(res)
    setIsLoading(() => false)
    setIsInit(() => false)
  }

  useEffect(() => {
    getBillingHistory();
  }, []);

  const toAvailableCredit = (selectedHistory: IBillingHistory | undefined) => { 
    if(!selectedHistory) return ''
    const displayAmount = displayAmountCurrying(0, 2);
    const amount = parseFloat(selectedHistory.creditLimit) - parseFloat(selectedHistory.previousClosingBalance)
    return `${t("hkd")} ${displayAmount(amount)}`
  }

  const toAmount = (amount: number | string | undefined) => {
    const displayAmount = displayAmountCurrying(0, 2);
    return `${t("hkd")} ${displayAmount(amount || 0)}`
  }

  if (isLoading) return <LoadingDialog forceOpen={true} />
  if (!isInit && billingHistories.length === 0) return <>{t('no_result')}</>

  const labelElePairArrList: [string, JSX.Element][][] = [
    [
      [TK.cycleStartDate, <>{toDisplayDate(selectedHistory?.billingPeriodStart)}</>],
      [TK.cycleEndDate, <>{toDisplayDate(selectedHistory?.billingPeriodClose)}</>],
      [TK.cycleBalance, <>{toAmount(selectedHistory?.closingBalance)}</>],
      [TK.previousCycleBalance, <>{toAmount(selectedHistory?.previousClosingBalance)}</>],
      [TK.minimumDue, <>{toAmount(selectedHistory?.minimumDue)}</>],
      [TK.pastDue, <>{toAmount(selectedHistory?.pastDue)}</>],
      [TK.totalDue, <>{toAmount(selectedHistory?.totalDue)}</>],
      [TK.paymentDueDate, <>{toDisplayDate(selectedHistory?.repaymentPeriodEnd)}</>],
      [TK.penalizationDate, <>{getPenalizationDate(selectedHistory?.gracePeriodEnd)}</>],
      [TK.creditLimit, <>{toAmount(selectedHistory?.creditLimit)}</>],
      [TK.availableCredit, <>{toAvailableCredit(selectedHistory)}</>],
      [TK.payments, <>{toAmount(selectedHistory?.payments)}</>],
      [TK.credits, <>{toAmount(selectedHistory?.credits)}</>],
      [TK.purchasesCharged, <>{toAmount(selectedHistory?.purchaseBalance)}</>],
      [TK.cashAdvanceCharged, <>{toAmount(selectedHistory?.cashAdvanceBalance)}</>],
      [TK.fundsTransferCharged, <>{toAmount(selectedHistory?.fundTransferBalance)}</>],
      [TK.feeCharged, <>{toAmount(selectedHistory?.financialCharge)}</>],
      [TK.lateCharge, <>{toAmount(selectedHistory?.lateCharge)}</>],
      [TK.interestCharged, <>{toAmount(selectedHistory?.totalInterest)}</>],
      [TK.totalChargedForCredit, <>{toAmount(selectedHistory?.feeAdjustment)}</>],
      [TK.estimatedInterest, <>{`${toAmount(selectedHistory?.estimatedInterest)}`}</>],
      [TK.interestAer, <>{`${selectedHistory?.annualEffectiveRateFee} %`}</>],
      [TK.interestApr, <>{`${selectedHistory?.annualEffectiveRatePurchase} %`}</>],
      [TK.delinquentPeriods, <>{selectedHistory?.delinquentPeriodCounter}</>],
      [TK.postedRewards, <>{toAmount(selectedHistory?.postedRewards)}</>],
      [TK.totalUnpaidDueAmountForLoans, <>{toAmount(selectedHistory?.totalUnpaidDueAmountForLoans)}</>]
    ]
  ]

  const startDate = selectedHistory?.billingPeriodStart
  const endDate = selectedHistory?.billingPeriodClose

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <SingleSelection
        sx={{ formControl: { width: '250px' } }}
        value={selectedBillingCloseDate}
        onChange={(e) => {
          setSelectedBillingCloseDate(e.target.value)
          const foundHistory = billingHistories.find(item =>
            item.billingPeriodClose.toString() === e.target.value)
          setSelectedHistory(foundHistory)
        }}
        isNoSorting
        enumData={selectOptions}
        nameFn={(item) => item}
        clearSelect={() => { }}
      />
      <Button
        disabled={!selectedHistory}
        size="medium"
        variant='outlined'
        color='info'
        sx={{ mt: 2, mr: 2, width: '200px' }}
        onClick={() => window.open(
          `/cardTransaction?completedTransaction=${EnumYerOrNo.yes}&hidden=${EnumYerOrNo.no}&startDate=${startDate}&endDate=${endDate}`,
          "_blank",
          "noopener,noreferrer"
        )}
      >
        {t('view_cycle_transactions')}
      </Button>
      <GridBoxSections
        sectionHeaderList={[TK.billingHistory]}
        labelElePairArrList={labelElePairArrList}
      />
    </Box>
  )
}

export default ContentBillingHistory